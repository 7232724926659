<template>
  <section class="success">
    <popup-base
      v-show="wantToMakeOffer"
      class="popo md:pt-10 md:pb-4 w-full"
      @clickOnBackground="logState"
    >
      <div class="cardinal mr-auto ml-auto">
        <div
            class="flex justify-center"
        >
          <easy-spinner
              type="dots"
              color="white"
              size="100"
          />
        </div>
      </div>
    </popup-base>
  </section>
</template>

<script>
import PopupBase from '../helper/add/popupfat'

export default {
  name: 'Success',
  components: { PopupBase },
  props: {
    activation: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      wantToMakeOffer: false,
    }
  },

  created() {
    this.wantToMakeOffer = this.activation
  },

  methods: {
    fermer () {
      this.$emit('oga', false)
    },
    logState (answer) {
      this.wantToMakeOffer = answer
      this.$emit('oga', false)
    }
  }
}
</script>

<style lang="scss"  scoped>
    @import "../../assets/styles/sass/mixin";
    .success{
      z-index: 999;
    }
    .cardinal{
      width: 40%;
    }

    @media screen and (max-width: 700px){
      .success-container {
        width: 100%;
      }
      .titre{
        font-size: 20px;
      }
      .bloc1{
        width: 100%;
      }
      .bloc2{
        width: 100%;
        font-size: 18px;
      }
      .button{
        width: 100%;
      }
    }
</style>
